import * as React from "react"
import Layout from "../../../components/Layout"
import { Row, Col } from "react-bootstrap"
import Section from "../../../components/Section"
import { useStaticQuery, graphql, Link } from "gatsby"

const IndexPage = () => {
    const data = useStaticQuery(graphql`
        {
            allConferenceAwardAssetsJson {
                edges {
                    node {
                        name
                        filename
                        horizontalHref
                        squareHref
                        description
                        background
                    }
                }
            }
        }
    `)

    return (
        <Layout pageTitle="Awards - Award Badges">
            <Row>
                <Col>
                    <Link to="/awards/sme-awards">Back</Link>
                </Col>
            </Row>
            <Row className="mt-2">
                <Col md={8}>
                    
                    <h3>Badges</h3>
                    <p className="para">
                        Stick a badge on your social media images, email
                        signatures, website and where ever you want to shout
                        about being an award nominee or finalist!
                    </p>
                    <p className="para">
                        Don't keep your nomination quiet - raise your profile as
                        a Women's Business Club award nominee!
                    </p>
                </Col>
            </Row>
            {data.allConferenceAwardAssetsJson.edges.map(({ node }, i) => (
                <LogoRow key={i} node={node} />
            ))}
        </Layout>
    )
}

const LogoRow = ({ node }) => {
    return (
        <Row className="mt-5">
            <Col md={6}>
                <Section bg={node.background} centered className="rounded p-2">
                    <img
                        src={`/img/download/awards/${node.filename}.png`}
                        style={{ maxHeight: "250px" }}
                        alt=""
                    />
                </Section>
            </Col>
            <Col>
                <h2>{node.name}</h2>
                <p className="para">{node.description}</p>
                
                {node.horizontalHref && (
                    <a
                        href={node.horizontalHref}
                        className="btn btn-primary m-3"
                        rel="noopener noreferrer"
                        target="_blank"
                    >
                        Horizontal Template
                    </a>
                )}
                {node.squareHref && (
                    <a
                        href={node.squareHref}
                        className="btn btn-primary m-3"
                        rel="noopener noreferrer"
                        target="_blank"
                    >
                        Square Template
                    </a>
                )}
            </Col>
        </Row>
    )
}

export default IndexPage
